<template>
  <!-- Footer-->
  <footer class="footer bg-black small text-center text-white-50">
    <div class="container px-4 px-lg-5">Copyright &copy; Profinterior <span class="numbers">2022</span></div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>