<template>
  <!-- About-->
  <section class="about-section text-center" id="about">
    <article class="container text-white-50 p-5">
      <!-- <h2>Дизайн</h2> -->
      <blockquote>Авторский дизайн интерьера</blockquote>
      <blockquote>Опыт работы в сфере архитектуры и дизайна 20 лет</blockquote>
      <blockquote>
        Комплексные решения по разработке дизайна интерьера частных
        апартаментов, коттеджей, ресторанов.
      </blockquote>
      <blockquote>
        Cоздание эксклюзивных интерьеров в любом стиле-классика, фьюжн,
        модернизм, лофт, восточный, кантри, скандинавский, шебби-шик,
        нео-классика.
      </blockquote>
      <blockquote>Полное авторское сопровождение проекта под ключ.</blockquote>
      <blockquote>
        Отделочные ремонтные работы любой сложности в соответствии с дизайн
        проектом.
      </blockquote>
      <blockquote>
        Индивидуальный подход к каждому клиенту с учетом всех требований
        заказчика.
      </blockquote>
      <blockquote>
        Декорирование. Живопись. Графика. Архитектурная визуализация.
      </blockquote>
      <blockquote>Авторское ведение проекта.</blockquote>
      <blockquote>
        Подбор мебели ,освещения, аксессуаров,отделочных материалов под ключ.
      </blockquote>
    </article>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.about-section {
  padding-top: 2rem;
  background: linear-gradient(
    to bottom,
    #000 0%,
    rgba(0, 0, 0, 0.9) 75%,
    rgba(0, 0, 0, 0.8) 100%
  );
}
.about-section p {
  margin-bottom: 5rem;
}
</style>