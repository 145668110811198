<template>
  <div class="home">
    <MainPage />
  </div>
</template>

<script>
import MainPage from "./MainPage.vue";

export default {
  name: "Home",
  components: {
    MainPage,
  },
};
</script>
